@mixin white-gradient {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

$animationSpeed: 40s;

// Animation
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

// Styling
.slider {
  background: white;
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::before,
  &::after {
    @include white-gradient;
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .slide-track {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slide {
    height: 100px;
    width: 250px;
  }
}
.container-login-component {
  height: 100vh;
}
.input-with-icon {
  background: #f1f1f1;
  border: none;
  border-radius: 5px;
  height: 68px;
  padding-right: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8rem;
}
.btn-connexion {
  background: #cc1b0b;
  border-radius: 5px;
  border: 1px solid #cc1b0b;
  width: 100%;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #f7f7fc;
  transition: 0.3s ease-in-out;
  padding: 1rem;
}
.btn-connexion:hover {
  color: #cc1b0b;
  background: transparent;
}
.mdpOublie {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #cc1b0b;
  transition: 0.2s ease;
  border-style: none;
  background: transparent;
}
.mdpOublie:hover {
  text-decoration: none;
  color: #cc1b0b;
}
.label-input {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  color: #555555;
}
.btn-connexion-google {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #1c1c1c;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 5px;
  background: #fff;
  padding: 1rem;
  width: 100%;
}
.btn-connexion-google:hover {
  color: #cc1b0b;
  border: 1px solid #cc1b0b;
  background: transparent;
  transition: 0.2s ease;
}
.btn:focus {
  box-shadow: none;
}
.form-control:focus {
  box-shadow: none !important;
  border-color: none !important;
}
.container-taf {
  padding-left: 4rem !important;
  padding-right: 4rem;
  // max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
.no-link:hover {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

:root {
  --checkbox-size: 25px;
  --bg-color: #ffa69e;
  --checkbox-color: #22636163;
  --hover-color: #226361;
  --tick-color: #226361;
}
//   label.checkbox {
//       display: inline-block;
//       margin: 0 calc(var(--checkbox-size) * 0.25);
//       width: var(--checkbox-size);
//       height: var(--checkbox-size);
//       border: calc(var(--checkbox-size) * 0.125) solid var(--checkbox-color);
//       border-radius: 12.5%;
//       -webkit-transition: 400ms 100ms ease-out;
//       -o-transition: 400ms 100ms ease-out;
//       transition: 400ms 100ms ease-out;

//   }
//   label.checkbox:hover {
//       border-color: var(--hover-color);
//   }
//   input[type="checkbox"] {
//       position: absolute;
//       left: -1000px;
//   }
.tick {
  position: relative;
  right: calc(var(--checkbox-size) * -0.5);
  top: calc(var(--checkbox-size) * -0.18);
  width: calc(var(--checkbox-size) * 0.25);
  height: calc(var(--checkbox-size) * 0.75);
  border-right: calc(var(--checkbox-size) * 0.25) solid var(--tick-color);
  border-bottom: calc(var(--checkbox-size) * 0.25) solid var(--tick-color);
  -webkit-transform: rotate(45deg) scale(0);
  -ms-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  opacity: 0;
  -webkit-transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.5);
  -o-transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.5);
  transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.5);
}
.tick:before {
  content: "";
  position: absolute;
  left: calc(var(--checkbox-size) * -0.125);
  bottom: calc(var(--checkbox-size) * -0.25);
  border: calc(var(--checkbox-size) * 0.125) solid var(--tick-color);
  border-radius: 50%;
}
.tick:after {
  content: "";
  position: absolute;
  right: calc(var(--checkbox-size) * -0.25);
  top: calc(var(--checkbox-size) * -0.125);
  border: calc(var(--checkbox-size) * 0.125) solid var(--tick-color);
  border-radius: 50%;
}
input[type="checkbox"]:checked + label .tick {
  opacity: 1;
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
}
input[type="checkbox"]:focus + label {
  -webkit-animation-name: cb-pop;
  animation-name: cb-pop;
  -webkit-animation-duration: 400ms;
  animation-duration: 400ms;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes cb-pop {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  33% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  66% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    tranform: scale(1);
  }
}

@keyframes cb-pop {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  33% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  66% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    tranform: scale(1);
  }
}

.custom-file-label::after {
  display: none;
}
.custom-file-label-create-content {
  background-color: #f8f8f8;
  border-radius: 55px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 50px;
  padding: 1rem 3rem 1rem 2rem;
  color: rgba(0, 0, 0, 0.8);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid transparent;
}
.icon-import-file-label {
  position: absolute;
  right: 10px;
  width: 15px;
  z-index: 1;
  top: 35%;
  color: #226361;
}
.btn-submit {
  width: 100%;
  background: #226361;
  border-radius: 40px;
  border-color: #226361;
  padding: 17px;
  text-transform: capitalize;
  font-weight: 600;
}
.react-responsive-modal-modal {
  width: 100%;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}
.react-responsive-modal-modal h2 {
  color: #226361;
}
.sia-alert-danger {
  display: block;
  width: 100%;
  border-radius: 25px;
  font-weight: 600;
}

$gutter: 2%;
$gutterDbl: $gutter * 2;
.limited.collapse {
  display: block !important;
  .p-theme-collapse {
    position: absolute;
    left: 5%;
    top: 56%;
  }
}
.limited .collapse {
  width: 100% - $gutterDbl;
  margin: 0 $gutter 15px;
  border-radius: 4px;
  background-color: #eee;
}
div.collapse-title {
  display: table;
  padding: 20px 20px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  border-radius: 4px;
  color: #fff;
  user-select: none;
  border-bottom: 1px solid rgba(204, 204, 204, 0.6);
  position: relative;
}
.p-theme-collapse {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.1em;
  color: rgba(0, 0, 0, 0.6);
}
.p-theme-collapse-position {
  position: absolute;
  left: 5%;
  top: 47px;
}
.h3-theme-collapse {
  display: table-cell;

  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.1em;
  color: #000000;
  margin: 0;
  width: 95%;
}

span.collapse-title-symbol {
  display: inline-block;
  font-size: 0.8em;
  text-align: right;
  vertical-align: top;
  background: #cc1b0b;
  border-radius: 50%;
  padding: 2px;

  &::after {
    content: "+";
    padding: 5px;
  }
}

div.collapse-content {
  opacity: 0;
  height: 0;
  padding: 0 10px;
  display: none;

  ul,
  p {
    margin: 0;
  }
}

.collapse.open {
  div.collapse-title {
    border-bottom: 1px solid #bbb;
    border-radius: 4px 4px 0 0;
  }
  span.collapse-title-symbol::after {
    content: "\2212\FE0E";
  }

  div.collapse-content {
    display: block;
    animation-name: collapse-open;
    animation-duration: 200ms;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
  }
  .p-theme-collapse {
    position: absolute;
    left: 5%;
    top: 47px;
  }
}

.collapse.close {
  div.collapse-content {
    animation-name: collapse-closed;
    animation-duration: 200ms;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
  }
  .p-theme-collapse {
    position: absolute;
    left: 5%;
    top: 56%;
  }
}

.close {
  font-size: inherit !important;
}
@keyframes collapse-open {
  from {
    height: 0;
    padding: 0 10px;
  }
  to {
    display: block;
    height: auto;
    padding: 10px;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes collapse-closed {
  from {
    height: auto;
    padding: 10px;
  }
  to {
    height: 0;
    padding: 0 10px;
  }
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.list-collapse {
  display: flex;
  justify-content: space-between;
}

.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;

  &:hover {
    .checkbox-checkmark {
      border: 2px solid rgba(6, 100, 12, 0.6);
    }
  }
}

.checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:focus ~ .checkbox-checkmark-box {
    border-color: rgba(6, 100, 12, 0.6);
  }

  &:checked ~ .checkbox-checkmark-box {
    .checkbox-checkmark {
      &:after {
        display: block;
      }
    }
  }

  &:checked ~ .checkbox-checkmark-box {
    .checkbox-checkmark {
      background-color: #cc1b0b;
      border: 2px solid #cc1b0b;
    }
  }

  &:disabled ~ .checkbox-checkmark-box {
    .checkbox-checkmark {
      border: 2px solid #b0b0b0;
      cursor: not-allowed;
    }
  }

  &:disabled {
    &:checked ~ .checkbox-checkmark-box {
      .checkbox-checkmark {
        background-color: #b0b0b0;

        &:after {
          background: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.8866 9.91722L0.0873348 5.36761C0.0596566 5.34137 0.037692 5.31018 0.0227039 5.27582C0.00771585 5.24146 0 5.20461 0 5.1674C0 5.13019 0.00771585 5.09335 0.0227039 5.05899C0.037692 5.02463 0.0596566 4.99343 0.0873348 4.96719L1.29893 3.81086C1.41471 3.70049 1.60183 3.70049 1.71761 3.81086L4.87718 6.80501C4.99296 6.91538 5.18143 6.91409 5.2972 6.80372L12.2787 0.0839022C12.3945 -0.0277526 12.5829 -0.0277526 12.7001 0.0826188L13.913 1.23895C14.0288 1.34932 14.0288 1.52771 13.9143 1.63809L6.30821 8.95468L6.30956 8.95597L5.30662 9.91722C5.19085 10.0276 5.00238 10.0276 4.8866 9.91722Z' fill='%2374767B'/%3E%3C/svg%3E%0A")
            no-repeat center;
          background-size: contain;
        }
      }
    }
  }
}

.checkbox-checkmark-box {
  min-width: 26px;
  max-width: 26px;
  min-height: 26px;
  max-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 50%;
}

.checkbox-checkmark {
  min-width: 22px;
  max-width: 22px;
  min-height: 22px;
  max-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  cursor: pointer;

  &:after {
    content: "";
    display: none;
    background: url(../images/icons/done.png) no-repeat center;
    width: 24px;
    height: 20px;
    background-size: contain;
  }
}
.span-for-sale-item {
  display: inline-block;
  background: #ee3434;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 154.4%;
  color: #ffffff;
  padding: 0.5rem;
  text-align: center;
  white-space: nowrap;
  height: max-content;
}
.text-prix-card-item-listing {
  font-style: normal;
  font-weight: bold;
  font-size: clamp(20px, 4vw, 25px);
  line-height: 154.4%;
  color: #cc1b0b;
  text-align: right;
}
.bottom-card-item-listing {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  width: 93%;
  bottom: 0;
  padding: 15px 0;
}
.navbar-light .navbar-toggler {
  border-color: transparent !important;
}
.navbar-toggler {
  padding: 0;
}
.limited .collapse:not(.show) {
  display: block;
}

.btn-taf-primary {
  background: #cc1b0b !important;
  border-radius: 5px !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  text-align: center;
  color: #ffffff;
  border: none !important;
  outline: none !important;
  text-transform: uppercase !important;
  padding: 1rem 2rem !important;
}
.btn-taf-primary:hover {
  background: #cc1b0b !important;
  color: #ffffff;
}

.card-img-carousel-team {
  max-width: 100%;
	height: 100%;
  object-fit: fill;
}

.rec-item-wrapper {
  padding: 20px !important;
}
.img-cover {
  min-height: 300px;
  max-height: 300px;
  object-fit: cover;
}
.icon__listing {
  color: #cc1b0b;
}

@media(max-width: 425px) {
  .card-img-carousel-team {
    max-height: 400px;
    min-height: 400px;
  }
}

@media(min-width: 426px) and (max-width: 768px) {
  .card-img-carousel-team {
    max-height: 400px;
    min-height: 400px;
  }
}

@media(min-width: 769px) and (max-width: 1024px) {
  .card-img-carousel-team {
    max-height: 400px;
    min-height: 400px;
  }
}
@media(min-width: 1025px) and (max-width: 1440px)  {
  .card-img-carousel-team {
    // max-height: 282px;
    max-height: 400px;
    min-height: 400px;
  }
}
@media(min-width: 1441px) {
  .card-img-carousel-team {
    max-height: 400px;
    min-height: 400px;
  }
}