.error-page-component {
  margin-top: 15%;
}
.error-page-section-row {
  padding: 20px 0 10px 0;
  align-items: center;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.error-page-section-title {
  color: #cc1b0b !important;
}

.error-page-textual p {
  color: #cc1b0b !important;
}

.return-to-homepage-btn {
  background: #cc1b0b !important;
  color: #ffffff !important;
  padding: 10px 30px;
  border-radius: 15px;
  border-color: #cc1b0b !important;
  transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
}

.error-page-code-textual-container {
  padding: 30px;
}

.error-page-code {
  font-size: 200px;
  color: #cc1b0b !important;
}

#error-page-section {
  background: #fff;
}

.return-to-homepage-btn:hover {
  background: transparent !important;
  color: #cc1b0b !important;
  border-radius: 50px;
}
img.img-404 {
  width: 400px;
  border: 1px solid #cc1b0b;
}
