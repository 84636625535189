.banniere {
  /* background-image: url(../../../images/banniere.png);
      background-size: cover;
      background-repeat: no-repeat; */
  width: 100%;
  height: 100%;
  position: relative;
  /* background-position: 50%; */
}

.titre-banniere,
.titre-banniere p strong {
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 86px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.carousel-caption {
  bottom: inherit !important;
  right: inherit !important;
  top: 50%;
  left: 50% !important;
  transform: translate(-50%, -50%);
}
.div-content-img-scroll {
  padding-right: 12%;
  padding-left: 0;
}
.container-bloc-filter {
  margin-top: -6%;
  z-index: 999;
}
#profile-choice-component {
  margin-bottom: 100px;
}
.card-profile-choice {
  background: #ffffff;
  box-shadow: 0px 0px 43px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 0.5rem 3rem;
}
.titre-profile-choice {
  font-style: normal;
  font-weight: normal;
  font-size: clamp(14px, 2.5vw, 20px);
  line-height: 154.4%;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 20px;
}
.profile-choice-item {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  text-align: center;
  background: url(../../../images/icons/down.png) 90% 47% / 13px no-repeat #fff !important;
  appearance: none;
  height: 59px;
  width: 100%;
  border: none;
  font-size: 15px;
}
input.profile-choice-item {
  background: #ffffff !important;
}
.bloc-contain-titre-featured-listing {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titre-featured-listing {
  font-style: normal;
  font-weight: bold;
  font-size: clamp(20px, 5vw, 36px);
  line-height: 154.4%;
  color: #000000;
}
.btn-see-all {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 154.4%;
  color: #cc1b0b;
}
.btn-see-all:hover {
  background: #cc1b0b;
  color: #fff;
  border: 1px solid #cc1b0b;
}
.titre-card-item {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 154.4%;
  color: #000000;
}
.text-card-item {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 154.4%;
  color: #000000;
  display: block;
  text-overflow: Ellipsis;
  word-wrap: break-Word;
  overflow: hidden;
  max-height: 5.7em;
  line-height: 1.8em;
  text-transform: capitalize;
}
.bloc-card-div-border {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f3f3f3;
  /* padding: 1rem; */
}
.container-heart-icon {
  position: absolute;
  right: 2%;
  background: #ffffff;
  box-shadow: 0px 4px 43px rgba(0, 0, 0, 0.08);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-appart-item {
  border: none;
  background: #ffffff;
  box-shadow: 0px 0px 43px rgba(0, 0, 0, 0.08);
  border-radius: 10px 0px 0px 0px;
}
.card-appart-item:hover {
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
    0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
    0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}
.div-container-statut {
  border-radius: 5px;

  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20%;
  color: #ffffff;
  position: absolute;
  top: 5%;
  left: 5%;
  padding: 1rem;
}
.div-container-statut.span-for-sale {
  background: #cc1b0b;
}
.div-container-statut.span-for-rent {
  background: #06640c;
}
.bloc-contain-prix {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 154.4%;
  color: #ffffff;
  position: absolute;
  left: 5%;
  bottom: 42%;
}
.para-text-div-border {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 154.4%;
  color: #000000;
  text-align: justify;
  white-space: nowrap;
}
.section-images {
  background: #cc1b0b;
  padding: 2rem 0;
}

.titre-section-images {
  font-style: normal;
  font-weight: bold;
  font-size: clamp(20px, 3vw, 30px);
  line-height: 154.4%;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  z-index: 100 !important;
}
.img-carousel-item {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

#carousel-item .section-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#carousel-item .insights {
  height: 26vw;
  padding: 2vw 0;
  width: 100%;
  overflow: hidden;
}

#carousel-item .insights__list {
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 19vw;
  width: 100%;
  display: flex;
  align-items: center;
}

#carousel-item .insights__list__item {
  background-color: #fff;
  width: 22vw;
  height: 60%;
  padding: 0 40px;
  float: left;
  border-radius: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
  transition: opacity 0.3s ease-out;
}

#carousel-item .insights__list__item.is_active {
  width: 10vw;
  height: 100%;
  opacity: 1;
}

#carousel-item .insights__list__item.hovered {
  opacity: 1;
}

#arrowLeft,
#arrowRight {
  width: 10vw;
  height: 40vh;
  position: fixed;
  cursor: pointer;
}

#arrowLeft {
  left: 0;
  top: 50%;
}

#arrowRight {
  right: 0;
  top: 50%;
}
.text-temoignage-client {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 154.4%;
  color: #000000;
  text-align: justify;
}
.nom-client-carousel {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 154.4%;
  color: #ee3434;
  white-space: nowrap;
}
.libelle-client-carousel {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 154.4%;
  color: #000000;
  white-space: nowrap;
}
/* #carousel-item .insights__list__item.is_active .container-temoignage {
        display: inline;
        position: absolute;
        z-index: 9;
        background: #fff;
        margin-left: 195px;
    }
    .alice-carousel__stage-item.__active .container-temoignage {
        display: inline;
    } */

/* .container-temoignage {
          display: none;
    } */
.section-lastest-news {
  background: #f5f5f5;
}
.text-calendar-card {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #828282;
}
.card-title-lastest-news {
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 25px;
  color: #333333;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 250px;
}
.card-text-lastest-news {
  height: 90px;
}
.card-text-lastest-news,
.card-text-lastest-news p {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
  color: #828282;
  text-align: justify;
  word-wrap: break-Word;
  /* overflow: hidden; */
  /* max-height: 5.7em; */
  line-height: 1.8em;
  display: block;
}
.btn-continue-card {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.1em;
  color: #06640c;
  background: transparent;
}
.btn-continue-card:hover {
  border: 1px solid #06640c;
  color: #06640c;
  text-decoration: none;
  padding: 1rem !important;
  text-align: center;
}
.form-langue-footer {
  background: url(../../../images/Vector.png) 90% 47% / 13px no-repeat #fff !important;
  appearance: none;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.204545px;
  color: #000;
  mix-blend-mode: normal;
  opacity: 0.5;
}
#section07 a p {
  writing-mode: vertical-rl;
  text-orientation: mixed;

  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 12px;
  color: #ffffff;
}

.div-container-p-scroll {
  position: absolute;
  top: -2%;
  left: 36px;
}
.p-t-200-mobile {
  padding-top: 200px;
}
.box {
  position: absolute;
  top: 50%;
  /* top: 0; */
  transform: translate(-50%, -50%);
  left: -221px;
}
.div-container-titre-banniere-accueil .div-container-titre-banniere-accueil {
  position: absolute;
  top: -5%;
  left: 13%;
}
.div-container-titre-banniere-accueil {
  width: 100%;
  text-align: center;
}
.titre-banniere > p {
  color: #ffffff;
}
.box p {
  writing-mode: vertical-rl;
  text-orientation: mixed;

  font-style: normal;
  font-weight: 500;
  font-size: 29px;
  line-height: 17px;
  color: #ffffff;
}
.box span {
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 6px solid white;
  border-right: 6px solid white;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.box span:nth-child(2) {
  animation-delay: -0.2s;
}

.box span:nth-child(3) {
  animation-delay: -0.4s;
}
#carousel-accueil .carousel-indicators {
  bottom: 20% !important;
}
#carousel-accueil .carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

/* css carousel clients */

.banner-stories__item-wrapper .slick-dots li {
  cursor: pointer;
  display: inline-block;
  height: 15px;
  margin: 0 8px;
  position: relative;
  width: 15px;
}

.banner-stories__item-wrapper .slick-dots li:last-child {
  margin-right: 0;
}

.banner-stories__item-wrapper .slick-dots li.slick-active button,
.banner-stories__item-wrapper .slick-dots li button:hover {
  background: white;
}

.banner-stories__item-wrapper .slick-dots li button {
  background: rgba(255, 255, 255, 0.5);
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  padding: 0;
  -o-transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  -webkit-transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  width: 20px;
}

.banner-stories {
  background: linear-gradient(
    90deg,
    rgba(92, 33, 97, 1) 0%,
    rgba(166, 31, 86, 1) 100%
  );
  padding-bottom: 5%;
  padding-top: 5%;
  text-align: center;
}

.banner-stories h2 {
  color: white;
  font-size: 2.2rem;
}

.banner-stories__item {
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin: 0.5% 0.5% 1% 0.5%;
  opacity: 0.4;
  -o-transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  -webkit-transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.banner-stories__item .card {
  border: 0;
  border-radius: 0;
}

.banner-stories__item .card-body {
  height: 100%;
  padding: 6%;
}

.banner-stories__item .card-body p {
  font-size: 1.2rem;
  line-height: 1.6rem;
  padding-bottom: 2%;
}

.banner-stories__item .card-body h4 {
  font-size: 1.7rem;
}

.banner-stories__item img {
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  padding-top: 2%;
  width: 25%;
}

.banner-stories__item-wrapper .banner-stories__item.slick-current {
  opacity: 1 !important;
}

.banner-stories__item-wrapper .slick-dots {
  bottom: 0;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 5;
}
.carousel-control-prev.home-slide,
.carousel-control-next.home-slide {
  display: none;
}
.btn-filter-form {
  background: #cc1b0b !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px !important;
  line-height: 154.4% !important;
  color: #ffffff !important;
  height: 53px;
  padding: 10px !important;
}
.btn-filter-form:hover {
  color: #cc1b0b;
  border: 1px solid #cc1b0b;
  background: #fff;
}

button.close.closeBtnPromote {
  background: #ffffff;
  color: #cc1b0b;
}
.modal-header.header-modal-promote {
  background: #cc1b0b;
}
.react-multiple-carousel__arrow {
  display: none;
}
.height-card-body-project {
  height: 200px;
}
.carousel-indicators {
  z-index: 99;
}
.titre-banniere,
.titre-banniere p,
.titre-banniere p strong {
  text-shadow: 2px 2px 4px black;
}
.titre-banniere p {
  font-size: clamp(16px, 2.5vw, 30px) !important;
  line-height: clamp(20px, 3.5vh, 40px) !important;
}
.show-of-image {
  transition: all ease-out 200ms;
  height: 220px;
  object-fit: cover;
}
.show-of-text-container {
  box-sizing: content-box;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  transition: 0.5s ease;
}
.show-of-text-container:hover {
  background: rgba(0, 0, 0, 0.6);
}
/* .img-slider-accueil {
    height: 350px;
    object-fit: cover;
  } */
.width-img-popup {
  width: 100%;
}

.width-img-popup.img-ads {
  max-height: 400px !important;
}

.partner-img {
  width: 100%;
  object-fit: scale-down;
  max-width: 250px;
}
