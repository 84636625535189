.card-blog {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border: none;
}
.input-search-blog {
  background: #f3f3f3;
  border: none;
}
.input-search-blog svg {
  position: absolute;
  right: 2%;
}

/****************** Detail Blog ******************/
.card-statistique {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.card-statistique-text {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1em;
}

.card-blog-title {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.05em;
}

.card-blog-text {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 27px;
  text-align: justify;
  margin-bottom: 7px;
}

.blog-social-card-title {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.1em;
  color: #333333;
  padding-top: 5px;
}

.blog-social-icons-container {
  gap: 0.7rem;
  align-items: center;
}

.blog-social-icon {
  height: 13px;
  width: 13px;
}

/*************** author-blog-card **********/
.author-blog-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.avatar-author-blog {
  width: 100px;
}

.author-blog-card-name {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #000000;
  text-align: center;
}
.author-blog-socials-containers {
  display: flex;
  gap: 9px;
  justify-content: center;
}

.author-blog-description {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #4f4f4f;
}
/****************** Comments ***************/

.comment-details {
  display: flex;
  justify-content: space-between;
}

.border-comment-item {
  border-bottom: 1.5px solid #e0e0e0;
}

.author-comment-info-container {
  display: flex;
  gap: 20px;
}

.reply-comment-container {
  display: flex;
  align-items: center;
  gap: 4px;
  background: transparent;
  border: none;
}
.reply-icon-container,
.reply-text-container {
  height: 100%;
}

.number-of-comments,
.write-comment-text,
.category-title,
.trending-post-title {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 29px;
  color: #000000;
}

.author-comment-name {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 22px;
  color: #333333;
  margin: 0;
}

.date-comment {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
}

.reply-comment-text {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 29px;
  color: #4f4f4f;
}
.comment-text-container {
  padding-left: 4rem;
}

.comment-text {
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 29px;
  color: #828282;
  text-align: justify;
}
.see-more-button-container {
  display: flex;
  justify-content: center;
}
.see-more-button {
  display: flex;
  align-items: center;
  gap: 3px;
  background: transparent;
  border: none;
}
.arrow-down-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}
.see-more-comments {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
}

/* Form Add Comment */

.write-comment-container {
  display: flex;
  justify-content: space-between;
}

.submit-comment {
  background: #cc1b0b;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  border: none;
  padding: 1.2rem 3rem;
  white-space: nowrap;
}

/* ***categories Post  */
.search-input-container {
  position: relative;
}
.search-input {
  background: #ffffff;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  color: #000000;
  width: 100%;
  /* height: 70px; */
  padding: 0 0.8rem;
  outline: none;
}
.search-icon-container {
  width: 50px;
  height: 50px;
  background: #cc1b0b;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: 0.8rem;
}

.list-categories li {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 154.4%;
  color: #4f4f4f;
  display: flex;
  justify-content: space-between;
}

.list-categories li span {
  display: inline-block;
}

/* Trendings Posts */
.trending-post-card {
  display: flex;
  gap: 10px;
}

.trending-post-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.trending-post-date,
.trending-post-date h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #828282;
}

.btn-filter-category {
  border-radius: 5px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  background: transparent;
  color: #cc1b0b;
}
