.btn-more-projets {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 154.4%;
  text-transform: uppercase;
  color: #9a160a;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
}
.btn-more-projets:hover {
  background: #9a160a;
  color: #fff;
  text-decoration: none;
}

.btn-download {
  background: #444;
  color: #fff;
  text-decoration: none;
}
.btn-download:hover {
  background: #9a160a;
  color: #fff;
  text-decoration: none;
}
.text-prix-card-item-projets {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 154.4%;
  color: #06640c;
}
.img-projets-card-item {
  width: 40%;
}
.page-content-text {
  font-style: normal;
  font-weight: 300;
  font-size: clamp(16px, 3vw, 20px);
  line-height: 154.4%;
  text-align: center;
  color: #000000;
}
.div-statut-projet {
  position: absolute;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 154.4%;
  background: rgba(12, 181, 150, 0.92);
  border-radius: 5px;
  color: #ffffff;
  padding: 0.5rem 1rem;
  top: 5%;
  right: 9%;
}
.statut-project-details {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 154.4%;
  background: rgba(204, 27, 11, 0.62);
  border-radius: 10px;
  padding: 5px 15px;
  color: #ffffff;
  text-align: center;
}
.display-icon-detail-projet {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bloc-shadow {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.text-bold-detail-projet {
  font-style: normal;
  font-weight: normal;
  font-size: clamp(14px, 3vw, 18px);
  line-height: 154.4%;
  color: #000000;
}
.btn-theme-light {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 154.4%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  color: #9a160a;
  padding: 0.5rem;
}
.btn-theme-light:hover {
  background: #cc1b0b;
  border: 1px solid #cc1b0b;
  box-sizing: border-box;
  border-radius: 5px;
  color: #fff;
}
.text-collapse-project-details {
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: 0.1em;
  color: #828282;
}
.btn-titre-collapse {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 154.4%;
  color: #000000;
}
.empty-alert-container {
  background: #ff980010;
  padding: 10px;
  border-radius: 5px;
  color: #ff9800;
  font-weight: 600;
}
.taf-popover-body {
  display: flex;
  background: #cc1b0b;
  padding: 20px;
  border: 1px solid #cc1b0b;
  border-radius: 10px;
  animation: 0.4s slide-right;
}
.taf-popover-body button {
  margin-left: 5px;
  margin-right: 5px;
}
.container-taf.taf-relative-position-container {
  position: relative;
}
.taf-popover-container {
  position: absolute;
  right: 8%;
  z-index: 99;
  top: 2%;
}
.taf-popover-body h6 {
  color: #fff;
}
.project-detail-description img,
.project-detail-description video {
  width: 100%;
}
@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}
@keyframes slide-left {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0%;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
