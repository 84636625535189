.titre-bloc-left-contact {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: clamp(20px, 4vw, 36px) !important;
  line-height: 47px !important;
  letter-spacing: 0.1em !important;
  color: #000000 !important;
  margin: 0 !important;
}
.text-bloc-left-contact {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 154.4% !important;
  color: #000000 !important;
  text-align: justify !important;
}
.text-theme-contact {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 100.4% !important;
  color: #cc1b0b !important;
}
.text-contact-black {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: clamp(20px, 3vw, 24px) !important;
  line-height: 154.4% !important;
  color: #000 !important;
  text-wrap: balance;
}
.input-form-contact {
  background: #ffffff !important;
  /* opacity: 0.3 !important; */
  border: 1px solid #bdbdbd !important;
  box-sizing: border-box !important;

  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 154.4% !important;
  color: #828282 !important;
  height: 70px !important;
}
.label-form-contact {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 154.4% !important;
  color: #333333 !important;
}
.btn-theme-submit {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: capitalize !important;
  color: #ffffff !important;
  background: #cc1b0b !important;
  width: 100% !important;
  padding: 1rem !important;
}
.btn-theme-submit:hover {
  color: #cc1b0b !important;
  border: 1px solid #cc1b0b !important;
  background: #fff !important;
}
.p-contact {
  padding-top: 11.5rem !important;
}
