@import "../../styles/colors";

.icon-user-acount-dashboard {
	color: #768492;
	width: 18px;
	margin-left: 5px;
}

.user-acount-dashboard-header {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	display: flex;
	align-items: center;
	text-align: right;
	color: #011f3b;
	background: none;
	border: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0;
}
.image-user-acount-dashboard-header {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	margin-right: 15px;
}
button:focus {
	outline: 0px dotted;
	outline: 0px auto -webkit-focus-ring-color;
}
.h-logo-container {
	text-align: center;
}
img.logo-header {
	width: 100px;
	border-radius: 50%;
}
.headering {
	background: #fafafa;
	box-shadow: 0px 0px 36px rgba(0, 0, 0, 0.11);
	padding: 0 20px;
}
.navbar-brand-costum {
	padding: 0;
}
.div-content-header-contact {
	background: #f4efee;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0 50px;
}
.menu-link-contact-us {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	color: #000000;
}
.btn-icon-menu-header {
	color: #000;
}
.btn-icon-menu-header:hover {
	color: #cc1b0b;
	transition: 0.3s ease-in-out;
}
.text-langage {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: -0.468947px;
	color: #000000;
}
.img-social-icon-header {
	width: 19.84px;
	height: 19.84px;
	// margin-left: 10px;
	// margin-right: 10px;
}
.menu-item {
	list-style: none;
	float: left;
	padding: 0 15px;
	position: relative;
}
.menu-link {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 154.4%;
	text-transform: capitalize;
	color: #000000;
    white-space: nowrap;
}
.menu-link.active:after {
	content: "";
	width: 40px;
	height: 4px;
	background: #cc1b0b;
	position: absolute;
	bottom: -15px;
	left: 0;
	right: 0;
	margin: auto;
}
.menu-link.active {
	font-weight: bold;
	color: #cc1b0b;
}
a.menu-link:hover {
	color: #cc1b0b;
	text-decoration: none;
}
.bloc-icon-menu {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}
.ml-logo-desktop {
	margin-left: 10%;
}

.btn-langue-header {
	padding: 0;
}
.dropdown-toggle::after {
	border-top: 0.2em solid !important;
	border-right: 0.2em solid transparent !important;
	border-left: 0.2em solid transparent !important;
	vertical-align: 2px !important;
}
.link-item-header {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	color: #000000;
	padding: 0 20px;
}
.link-item-header:hover {
	color: inherit;
	font-weight: bold;
}
.menu-link-select-header {
	border: none;
	background: inherit;
}
.dropdown-item-menu-header {
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 154.4%;
	color: #000000;
}
.dropdown-item-menu-header:hover {
	background: #cc1b0b;
	color: #fff;
}
.dropdown-item-menu-header:focus {
	background: #cc1b0b;
	color: #fff;
}

.text-header {
	color: #000000 !important;
	margin-right: 0.2rem;
	font-family: "Eras", "Roboto", Arial, Helvetica, "Fira Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 24px;
  white-space: nowrap;
}

.header-contact {
	display: flex;
	align-items: center;
	border-right: 1px solid #e2dddd;
	font-size: 12px !important;
}

.no-link,
.no-link:hover {
	text-decoration: none !important;
	color: inherit !important;
}

.border-none {
	border: none !important;
}
.home-svg svg {
	height: 18px;
	width: 20px;
}
