.div-slider-img-container {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}

.slider-img-container {
  position: relative;
  overflow: hidden;
  background: rgba(33, 35, 42, 0.7);
  background-size: cover;
  background-repeat: no-repeat;
  /* background-image: url('../../../images/rectangleslider.png'); */
  width: 150px;
  height: 142.11px;
}

.icon-slider-img-container {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -10px;
  margin-left: -24px;
  gap: 5px;
  display: flex;
}

.icon-slider-img-close {
  color: #cc1b0b;
  width: 20px;
  height: 20px;
}
.icon-slider-img-check {
  color: #06640c;
  width: 20px;
  height: 20px;
}
