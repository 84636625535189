.nav-items-custom {
  margin-bottom: 20px;
}
.nav-link-vertival-custom {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #babec6;
  padding: 20px !important;
}

.nav-link-vertival-custom:hover {
  color: #9a160a;
  transition: 0.2s ease;
  border-radius: 20px;
}
.nav-link-vertival-custom.actived {
  background: #9a160a;
  box-shadow: 0px 20px 55px rgba(6, 100, 12, 0.1);
  border-radius: 10px;
  color: #fff;
}

.nav-link-vertival-custom:hover .icon-navbar-vertical {
  color: #fff;
}
.icon-navbar-vertical {
  width: 20px;
  margin-right: 15px;
  color: #babec6;
}
.hiddenable-navlink-label {
  display: none;
}
.hoverable:hover span.hiddenable-navlink-label {
  display: inline-block;
}
.navbar-vertical-customisation-v2:hover span.hiddenable-navlink-label {
  display: inline-block;
}
.hiddenable-navlink-label {
  padding-left: 10px;
}
.navbar-vertical-customisation-v2 {
  overflow: scroll;
  padding: 75px 0 150px 0;
  height: 100%;
  max-width: 17%;
  width: fit-content;
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 70px;
  z-index: 999;
}

.navbar-vertical-customisation-v2::-webkit-scrollbar {
  width: 3px;
  height: 100%;
}
.navbar-vertical-customisation-v2::-webkit-scrollbar-track {
  background: transparent;
}
.navbar-vertical-customisation-v2::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
  border: 3px solid transparent;
}
.select-langue-sidebar {
  border: none;
  color: #babec6;
  background: url(../../../images/icons/Vector.png) 90% 47% / 13px no-repeat
    transparent !important;
  appearance: none;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
}
#scrollbar {
  height: 100%;
  overflow-y: auto;
}
#scrollbar::-webkit-scrollbar {
  display: none;
}
.content {
  height: 350px;
}
