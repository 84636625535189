@import "~bootstrap/scss/bootstrap";
@import "App";
@import url("./components/user/front-home/FrontHome.css");
@import url("./components/user/listing/Listing.css");
@import url("./components/user/blog/Blog.css");

@font-face {
  font-family: "Eras";
  src: url("./font/eras-book.otf");
}

@font-face {
  font-family: "Eras";
  src: url("./font/eras-bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: "Eras";
  src: url("./font/eras-bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Eras";
  src: url("./font/eras-bold2.otf");
  font-weight: 800;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Eras", "Roboto", Arial, Helvetica, "Fira Sans";
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.carousel-container {
  margin-top: 4%;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: inherit !important;
}

.section-images {
  background: #cc1b0b;
  padding: 2rem 0;
}

.table th, .table td {
  vertical-align: middle !important;
}